import { navigate } from "gatsby-link"
import React, { useEffect, useRef, useState } from "react"

// components
import BottomButtonContainer from "../../components/commons/BottomButtonContainer"
import CommonHead from "../../components/commons/CommonHead"
import CommonInner from "../../components/commons/CommonInner"

// icons
import PenIcon from "../../images/paint/pencile_icon.svg"
import EraserIcon from "../../images/paint/eraser_icon.svg"
import UndoIcon from "../../images/paint/undo_icon.svg"

import * as styles from "./styles.module.scss"

const PAINT_MODE = {
  pen: "source-over",
  eraser: "destination-out",
}

let undoList = []

const Paint = () => {
  const [paintMode, setPaintMode] = useState(PAINT_MODE.pen)
  const [isEnabled, setIsEnabled] = useState(false)
  const [isPC, setIsPC] = useState(false)
  const canvasRef = useRef(null)

  const canvasColor = "white"
  const penColor = "black"
  const penWidth = 3

  const handleToWrite = () => {
    const canvas = canvasRef.current

    const imageBase64 = canvas.toDataURL()

    sessionStorage.setItem("image", imageBase64)
    navigate("/text")
  }

  let isDrag = false

  const click = e => {
    const canvas = canvasRef.current
    const context = canvas.getContext("2d")

    let x = e.pageX - canvas.getBoundingClientRect().left
    let y = e.pageY - canvas.getBoundingClientRect().top

    context.fillStyle = paintMode === PAINT_MODE.pen ? penColor : canvasColor
    context.beginPath()
    context.arc(x, y, penWidth / 2, 0, 2 * Math.PI, false)
    context.fill()
    checkCanvas()
  }

  const dragStart = e => {
    const canvas = canvasRef.current
    const context = canvas.getContext("2d")
    const canvasRect = canvas.getBoundingClientRect()

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
    undoList.push(() => {
      context.putImageData(imageData, 0, 0)
    })

    let x = 0
    let y = 0

    if (e.type === "touchstart") {
      if (e.touches.length === 1) {
        const touch = e.touches[0]

        x = touch.pageX - canvasRect.left
        y = touch.pageY - canvasRect.top
      }
    } else {
      x = e.pageX - canvasRect.left
      y = e.pageY - canvasRect.top
    }

    context.beginPath()
    context.moveTo(x, y)
    context.lineTo(x, y)
    context.stroke()

    isDrag = true
  }

  const draw = e => {
    if (!isDrag) {
      return
    }

    const canvas = canvasRef.current
    const context = canvas.getContext("2d")
    const canvasRect = canvas.getBoundingClientRect()

    let x = 0
    let y = 0

    if (e.type === "touchmove") {
      if (e.touches.length === 1) {
        const touch = e.touches[0]

        x = touch.pageX - canvasRect.left
        y = touch.pageY - canvasRect.top
      }
    } else {
      x = e.pageX - canvas.getBoundingClientRect().left
      y = e.pageY - canvas.getBoundingClientRect().top
    }

    context.lineTo(x, y)
    context.stroke()
  }

  const dragEnd = e => {
    isDrag = false

    checkCanvas()
  }

  const undo = () => {
    if (undoList.length > 0) {
      undoList.pop()()
    }

    checkCanvas()
  }

  const changePaintMode = mode => {
    if (canvasRef) {
      const context = canvasRef.current.getContext("2d")
      context.strokeStyle = mode === PAINT_MODE.pen ? penColor : canvasColor
      setPaintMode(mode)
    }
  }

  const setImage = ref => {
    const base64Image = sessionStorage.getItem("image")
    if (ref && base64Image) {
      const context = ref.current.getContext("2d")
      const image = new Image()
      image.src = base64Image
      image.onload = () => {
        context.drawImage(image, 0, 0)
      }
    }
  }

  const disableScroll = event => {
    event.preventDefault()
  }

  const checkCanvas = () => {
    const canvas = canvasRef.current
    const context = canvas.getContext("2d")

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
    const data = imageData.data

    let isWrite = false
    for (let i = 0, len = data.length; i < len; i += 4) {
      const tmp = data[i]
      if (tmp === 0) {
        isWrite = true
        break
      }
    }
    setIsEnabled(isWrite)
  }

  const setupCanvas = () => {
    const canvas = canvasRef.current
    const context = canvas.getContext("2d")

    context.fillStyle = canvasColor
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.lineCap = "round"
    context.lineJoin = "round"
    context.lineWidth = penWidth
    context.strokeStyle = penColor
  }

  const getDevice = () => {
    var ua = navigator.userAgent
    if (
      ua.indexOf("iPhone") > 0 ||
      ua.indexOf("iPod") > 0 ||
      (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
    ) {
      return "mobile"
    } else if (ua.indexOf("iPad") > 0 || ua.indexOf("Android") > 0) {
      return "tablet"
    } else {
      return "desktop"
    }
  }

  useEffect(() => {
    setImage(canvasRef)
  }, [canvasRef])

  useEffect(() => {
    setupCanvas()
    setIsPC(getDevice() === "desktop")
    document.addEventListener("touchmove", disableScroll, { passive: false })

    return () => {
      document.removeEventListener("touchmove", disableScroll, {
        passive: false,
      })
    }
  }, [])

  return (
    <div className={styles.paint}>
      <CommonInner>
        <CommonHead>今年やりたいことを描いてみよう</CommonHead>
      </CommonInner>
      <div className={styles.container}>
        <div className={styles.canvas_container}>
          {isPC ? (
            <canvas
              className={styles.canvas}
              onMouseDown={dragStart}
              onMouseMove={draw}
              onMouseUp={dragEnd}
              onClick={click}
              width="300"
              height="325"
              ref={canvasRef}
            />
          ) : (
            <canvas
              className={styles.canvas}
              onTouchStart={dragStart}
              onTouchMove={draw}
              onTouchEnd={dragEnd}
              onClick={click}
              width="300"
              height="325"
              ref={canvasRef}
            />
          )}
          <div className={styles.canvas_button_container}>
            <button
              className={`${styles.button} ${styles.pen} ${
                paintMode === PAINT_MODE.pen ? styles.selected : ""
              }`}
              onClick={() => changePaintMode(PAINT_MODE.pen)}
            >
              <img src={PenIcon} alt="ペン" />
            </button>
            <button
              className={`${styles.button} ${styles.eraser} ${
                paintMode === PAINT_MODE.eraser ? styles.selected : ""
              }`}
              onClick={() => changePaintMode(PAINT_MODE.eraser)}
            >
              <img src={EraserIcon} alt="消しゴム" />
            </button>
            <button
              className={`${styles.button} ${styles.undo}`}
              onClick={() => undo()}
            >
              <img src={UndoIcon} alt="戻る" />
            </button>
          </div>
        </div>
        <div className={styles.button_container}>
          <BottomButtonContainer
            clickNext={handleToWrite}
            disabled={!isEnabled}
            clickPrev={() => navigate(-1)}
            prevTitle="カルタ一覧に戻る"
          />
        </div>
      </div>
    </div>
  )
}

export default Paint
