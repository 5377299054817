import React from "react"

import * as styles from "./styles.module.scss"

const CircleButton = ({ handleClick, isDisabled, isCheck }) => {
  return (
    <button
      className={`${styles.circle_button} ${isCheck ? styles.check : ""}`}
      onClick={() => handleClick()}
      disabled={isDisabled}
    />
  )
}

export default CircleButton
