import React from "react"

import CircleButton from "../CircleButton"

import * as styles from "./styles.module.scss"

const BottomButtonContainer = ({
  clickNext,
  disabled,
  clickPrev,
  prevTitle = "戻る",
  isCheck = false,
}) => {
  return (
    <>
      <div className={styles.bottom_button_container}>
        <div className={styles.button_container}>
          <CircleButton
            handleClick={clickNext}
            isDisabled={disabled}
            isCheck={isCheck}
          />
        </div>
        <div className={styles.back_button_container}>
          <button onClick={clickPrev}>{prevTitle}</button>
        </div>
      </div>
      <div className={styles.next_button_container}>
        <CircleButton
          handleClick={clickNext}
          isDisabled={disabled}
          isCheck={isCheck}
        />
      </div>
    </>
  )
}

export default BottomButtonContainer
